import React, { useEffect , useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {

 const slides = [
        {
            img: 'assets/images/thumbs/b1.png', // Replace with your image path
        },
        {
            img: 'assets/images/thumbs/b1.png', // Replace with your image path
        },
        {
            img: 'assets/images/thumbs/b1.png', // Replace with your image path
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const showSlide = (index) => {
        if (index >= slides.length) {
            setCurrentSlide(0);
        } else if (index < 0) {
            setCurrentSlide(slides.length - 1);
        } else {
            setCurrentSlide(index);
        }
    };

    const changeSlide = (n) => {
        showSlide(currentSlide + n);
    };

  // Optional: Add useEffect for any additional initialization logic if needed
  useEffect(() => {
    // Any initialization logic can go here if necessary
  }, []);

  return (
    <>

        {/*  Home */}
        <div className="slider">
            <div className="slides" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.map((slide, index) => (
                    <div className="slide" key={index}>
                        <img src={slide.img} alt={`Banner ${index + 1}`} />
                        <div className="caption">
                        </div>
                    </div>
                ))}
            </div>
            <button className="prev" onClick={() => changeSlide(-1)}>&#10094;</button>
            <button className="next" onClick={() => changeSlide(1)}>&#10095;</button>
        </div>
 

        <div className="feature" id="featureSection">
  <div className="container container-lg">
    <div className="position-relative arrow-center">
      <div className="flex-align">
        <button
          type="button"
          id="feature-item-wrapper-prev"
          className="slick-prev slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1"
        >
          <i className="ph ph-caret-left" />
        </button>
        <button
          type="button"
          id="feature-item-wrapper-next"
          className="slick-next slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1"
        >
          <i className="ph ph-caret-right" />
        </button>
      </div>
      <div className="feature-item-wrapper">
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={400}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img1.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Vegetables
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={600}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img2.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Fish &amp; Meats
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={800}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img3.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Desserts
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img4.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Drinks &amp; Juice
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={1200}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img5.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Animals Food
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={1400}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img6.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Fresh Fruits
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={1600}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img7.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Yummy Candy
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={1800}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img2.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Fish &amp; Meats
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={2000}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img8.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Dairy &amp; Eggs
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={2200}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img9.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Snacks
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
        <div
          className="feature-item text-center wow bounceIn"
          data-aos="fade-up"
          data-aos-duration={2400}
        >
          <div className="feature-item__thumb rounded-circle">
            <a href="shop.html" className="w-100 h-100 flex-center">
              <img src="assets/images/thumbs/feature-img10.png" alt="" />
            </a>
          </div>
          <div className="feature-item__content mt-16">
            <h6 className="text-lg mb-8">
              <a href="shop.html" className="text-inherit">
                Frozen Foods
              </a>
            </h6>
            <span className="text-sm text-gray-400">125+ Products</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<section className="promotional-banner pt-80">
  <div className="container container-lg">
    <div className="row gy-4">
      <div
        className="col-xl-3 col-sm-6 col-xs-6 wow bounceIn"
        data-aos="fade-up"
        data-aos-duration={400}
      >
        <div className="promotional-banner-item position-relative rounded-24 overflow-hidden z-1">
          <img
            src="assets/images/thumbs/promotional-banner-img1.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1"
          />
          <div className="promotional-banner-item__content">
            <h6 className="promotional-banner-item__title text-32">
              Everyday Fresh Meat
            </h6>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        className="col-xl-3 col-sm-6 col-xs-6 wow bounceIn"
        data-aos="fade-up"
        data-aos-duration={600}
      >
        <div className="promotional-banner-item position-relative rounded-24 overflow-hidden z-1">
          <img
            src="assets/images/thumbs/promotional-banner-img2.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1"
          />
          <div className="promotional-banner-item__content">
            <h6 className="promotional-banner-item__title text-32">
              Daily Fresh Vegetables
            </h6>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        className="col-xl-3 col-sm-6 col-xs-6 wow bounceIn"
        data-aos="fade-up"
        data-aos-duration={800}
      >
        <div className="promotional-banner-item position-relative rounded-24 overflow-hidden z-1">
          <img
            src="assets/images/thumbs/promotional-banner-img3.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1"
          />
          <div className="promotional-banner-item__content">
            <h6 className="promotional-banner-item__title text-32">
              Everyday Fresh Milk
            </h6>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        className="col-xl-3 col-sm-6 col-xs-6 wow bounceIn"
        data-aos="fade-up"
        data-aos-duration={1000}
      >
        <div className="promotional-banner-item position-relative rounded-24 overflow-hidden z-1">
          <img
            src="assets/images/thumbs/promotional-banner-img4.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1"
          />
          <div className="promotional-banner-item__content">
            <h6 className="promotional-banner-item__title text-32">
              Everyday Fresh Fruits
            </h6>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="flash-sales pt-80 overflow-hidden">
  <div className="container container-lg">
    <div className="section-heading">
      <div className="flex-between flex-wrap gap-8">
        <h5 className="mb-0 wow bounceInLeft">Flash Sales Today</h5>
        <div className="flex-align gap-16 wow bounceInRight">
          <a
            href="shop.html"
            className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
          >
            View All Deals
          </a>
          <div className="flex-align gap-8">
            <button
              type="button"
              id="flash-prev"
              className="slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
            >
              <i className="ph ph-caret-left" />
            </button>
            <button
              type="button"
              id="flash-next"
              className="slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
            >
              <i className="ph ph-caret-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flash-sales__slider arrow-style-two">
      <div className="" data-aos="fade-up" data-aos-duration={600}>
        <div className="flash-sales-item rounded-16 overflow-hidden z-1 position-relative flex-align flex-0 justify-content-between gap-8">
          <img
            src="assets/images/bg/flash-sale-bg1.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1 flash-sales-item__bg"
          />
          <div className="flash-sales-item__thumb d-sm-block d-none">
            <img src="assets/images/thumbs/flash-sale-img1.png" alt="" />
          </div>
          <div className="flash-sales-item__content ms-sm-auto">
            <h6 className="text-32 mb-20">Fresh Vegetables</h6>
            <div className="countdown" id="countdown1">
              <ul className="countdown-list flex-align flex-wrap">
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="days" />
                  Days
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="hours" />
                  Hours
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="minutes" />
                  Min
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="seconds" />
                  Sec
                </li>
              </ul>
            </div>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="" data-aos="fade-up" data-aos-duration={1000}>
        <div className="flash-sales-item rounded-16 overflow-hidden z-1 position-relative flex-align flex-0 justify-content-between gap-8">
          <img
            src="assets/images/bg/flash-sale-bg2.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1 flash-sales-item__bg"
          />
          <div className="flash-sales-item__thumb d-sm-block d-none">
            <img src="assets/images/thumbs/flash-sale-img2.png" alt="" />
          </div>
          <div className="flash-sales-item__content ms-sm-auto">
            <h6 className="text-32 mb-20">Daily Snacks</h6>
            <div className="countdown" id="countdown2">
              <ul className="countdown-list flex-align flex-wrap">
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="days" />
                  Days
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="hours" />
                  Hours
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="minutes" />
                  Min
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="seconds" />
                  Sec
                </li>
              </ul>
            </div>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="" data-aos="fade-up" data-aos-duration={1400}>
        <div className="flash-sales-item rounded-16 overflow-hidden z-1 position-relative flex-align flex-0 justify-content-between gap-8">
          <img
            src="assets/images/bg/flash-sale-bg2.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 object-fit-cover z-n1 flash-sales-item__bg"
          />
          <div className="flash-sales-item__thumb d-sm-block d-none">
            <img src="assets/images/thumbs/flash-sale-img2.png" alt="" />
          </div>
          <div className="flash-sales-item__content ms-sm-auto">
            <h6 className="text-32 mb-20">Daily Snacks</h6>
            <div className="countdown" id="countdown3">
              <ul className="countdown-list flex-align flex-wrap">
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="days" />
                  Days
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="hours" />
                  Hours
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="minutes" />
                  Min
                </li>
                <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                  <span className="seconds" />
                  Sec
                </li>
              </ul>
            </div>
            <a
              href="shop.html"
              className="btn btn-main d-inline-flex align-items-center rounded-pill gap-8 mt-24"
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div className="product mt-24">
  <div className="container container-lg">
    <div className="row gy-4 g-12">
      <div
        className="col-xxl-2 col-lg-3 col-sm-4 col-6"
        data-aos="fade-up"
        data-aos-duration={200}
      >
        <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
          <a
            href="cart.html"
            className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
          >
            Add <i className="ph ph-shopping-cart" />
          </a>
          <a
            href="product-details.html"
            className="product-card__thumb flex-center"
          >
            <img src="assets/images/thumbs/product-img1.png" alt="" />
          </a>
          <div className="product-card__content mt-12">
            <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {" "}
                $28.99
              </span>
              <span className="text-heading text-md fw-semibold ">
                $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
              </span>
            </div>
            <div className="flex-align gap-6">
              <span className="text-xs fw-bold text-gray-600">4.8</span>
              <span className="text-15 fw-bold text-warning-600 d-flex">
                <i className="ph-fill ph-star" />
              </span>
              <span className="text-xs fw-bold text-gray-600">(17k)</span>
            </div>
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <a href="product-details.html" className="link text-line-2">
                Taylor Farms Broccoli Florets Vegetables
              </a>
            </h6>
            <div className="flex-align gap-4">
              <span className="text-main-600 text-md d-flex">
                <i className="ph-fill ph-storefront" />
              </span>
              <span className="text-gray-500 text-xs">
                By Lucky Supermarket
              </span>
            </div>
            <div className="mt-12">
              <div
                className="progress w-100  bg-color-three rounded-pill h-4"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={35}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-main-600 rounded-pill"
                  style={{ width: "35%" }}
                />
              </div>
              <span className="text-gray-900 text-xs fw-medium mt-8">
                Sold: 18/35
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xxl-2 col-lg-3 col-sm-4 col-6"
        data-aos="fade-up"
        data-aos-duration={400}
      >
        <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
          <a
            href="cart.html"
            className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
          >
            Add <i className="ph ph-shopping-cart" />
          </a>
          <a
            href="product-details.html"
            className="product-card__thumb flex-center"
          >
            <img src="assets/images/thumbs/product-img2.png" alt="" />
          </a>
          <div className="product-card__content mt-12">
            <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {" "}
                $28.99
              </span>
              <span className="text-heading text-md fw-semibold ">
                $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
              </span>
            </div>
            <div className="flex-align gap-6">
              <span className="text-xs fw-bold text-gray-600">4.8</span>
              <span className="text-15 fw-bold text-warning-600 d-flex">
                <i className="ph-fill ph-star" />
              </span>
              <span className="text-xs fw-bold text-gray-600">(17k)</span>
            </div>
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <a href="product-details.html" className="link text-line-2">
                Taylor Farms Broccoli Florets Vegetables
              </a>
            </h6>
            <div className="flex-align gap-4">
              <span className="text-main-600 text-md d-flex">
                <i className="ph-fill ph-storefront" />
              </span>
              <span className="text-gray-500 text-xs">
                By Lucky Supermarket
              </span>
            </div>
            <div className="mt-12">
              <div
                className="progress w-100  bg-color-three rounded-pill h-4"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={35}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-main-600 rounded-pill"
                  style={{ width: "35%" }}
                />
              </div>
              <span className="text-gray-900 text-xs fw-medium mt-8">
                Sold: 18/35
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xxl-2 col-lg-3 col-sm-4 col-6"
        data-aos="fade-up"
        data-aos-duration={600}
      >
        <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
          <a
            href="cart.html"
            className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
          >
            Add <i className="ph ph-shopping-cart" />
          </a>
          <a
            href="product-details.html"
            className="product-card__thumb flex-center"
          >
            <img src="assets/images/thumbs/product-img3.png" alt="" />
          </a>
          <div className="product-card__content mt-12">
            <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {" "}
                $28.99
              </span>
              <span className="text-heading text-md fw-semibold ">
                $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
              </span>
            </div>
            <div className="flex-align gap-6">
              <span className="text-xs fw-bold text-gray-600">4.8</span>
              <span className="text-15 fw-bold text-warning-600 d-flex">
                <i className="ph-fill ph-star" />
              </span>
              <span className="text-xs fw-bold text-gray-600">(17k)</span>
            </div>
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <a href="product-details.html" className="link text-line-2">
                Taylor Farms Broccoli Florets Vegetables
              </a>
            </h6>
            <div className="flex-align gap-4">
              <span className="text-main-600 text-md d-flex">
                <i className="ph-fill ph-storefront" />
              </span>
              <span className="text-gray-500 text-xs">
                By Lucky Supermarket
              </span>
            </div>
            <div className="mt-12">
              <div
                className="progress w-100  bg-color-three rounded-pill h-4"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={35}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-main-600 rounded-pill"
                  style={{ width: "35%" }}
                />
              </div>
              <span className="text-gray-900 text-xs fw-medium mt-8">
                Sold: 18/35
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xxl-2 col-lg-3 col-sm-4 col-6"
        data-aos="fade-up"
        data-aos-duration={800}
      >
        <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
          <a
            href="cart.html"
            className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
          >
            Add <i className="ph ph-shopping-cart" />
          </a>
          <a
            href="product-details.html"
            className="product-card__thumb flex-center"
          >
            <img src="assets/images/thumbs/product-img1.png" alt="" />
          </a>
          <div className="product-card__content mt-12">
            <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {" "}
                $28.99
              </span>
              <span className="text-heading text-md fw-semibold ">
                $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
              </span>
            </div>
            <div className="flex-align gap-6">
              <span className="text-xs fw-bold text-gray-600">4.8</span>
              <span className="text-15 fw-bold text-warning-600 d-flex">
                <i className="ph-fill ph-star" />
              </span>
              <span className="text-xs fw-bold text-gray-600">(17k)</span>
            </div>
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <a href="product-details.html" className="link text-line-2">
                Taylor Farms Broccoli Florets Vegetables
              </a>
            </h6>
            <div className="flex-align gap-4">
              <span className="text-main-600 text-md d-flex">
                <i className="ph-fill ph-storefront" />
              </span>
              <span className="text-gray-500 text-xs">
                By Lucky Supermarket
              </span>
            </div>
            <div className="mt-12">
              <div
                className="progress w-100  bg-color-three rounded-pill h-4"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={35}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-main-600 rounded-pill"
                  style={{ width: "35%" }}
                />
              </div>
              <span className="text-gray-900 text-xs fw-medium mt-8">
                Sold: 18/35
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xxl-2 col-lg-3 col-sm-4 col-6"
        data-aos="fade-up"
        data-aos-duration={1000}
      >
        <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
          <a
            href="cart.html"
            className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
          >
            Add <i className="ph ph-shopping-cart" />
          </a>
          <a
            href="product-details.html"
            className="product-card__thumb flex-center"
          >
            <img src="assets/images/thumbs/product-img5.png" alt="" />
          </a>
          <div className="product-card__content mt-12">
            <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {" "}
                $28.99
              </span>
              <span className="text-heading text-md fw-semibold ">
                $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
              </span>
            </div>
            <div className="flex-align gap-6">
              <span className="text-xs fw-bold text-gray-600">4.8</span>
              <span className="text-15 fw-bold text-warning-600 d-flex">
                <i className="ph-fill ph-star" />
              </span>
              <span className="text-xs fw-bold text-gray-600">(17k)</span>
            </div>
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <a href="product-details.html" className="link text-line-2">
                Taylor Farms Broccoli Florets Vegetables
              </a>
            </h6>
            <div className="flex-align gap-4">
              <span className="text-main-600 text-md d-flex">
                <i className="ph-fill ph-storefront" />
              </span>
              <span className="text-gray-500 text-xs">
                By Lucky Supermarket
              </span>
            </div>
            <div className="mt-12">
              <div
                className="progress w-100  bg-color-three rounded-pill h-4"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={35}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-main-600 rounded-pill"
                  style={{ width: "35%" }}
                />
              </div>
              <span className="text-gray-900 text-xs fw-medium mt-8">
                Sold: 18/35
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xxl-2 col-lg-3 col-sm-4 col-6"
        data-aos="fade-up"
        data-aos-duration={1200}
      >
        <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
          <a
            href="cart.html"
            className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 position-absolute inset-block-start-0 inset-inline-end-0 me-16 mt-16"
          >
            Add <i className="ph ph-shopping-cart" />
          </a>
          <a
            href="product-details.html"
            className="product-card__thumb flex-center"
          >
            <img src="assets/images/thumbs/product-img6.png" alt="" />
          </a>
          <div className="product-card__content mt-12">
            <div className="product-card__price mb-16">
              <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                {" "}
                $28.99
              </span>
              <span className="text-heading text-md fw-semibold ">
                $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
              </span>
            </div>
            <div className="flex-align gap-6">
              <span className="text-xs fw-bold text-gray-600">4.8</span>
              <span className="text-15 fw-bold text-warning-600 d-flex">
                <i className="ph-fill ph-star" />
              </span>
              <span className="text-xs fw-bold text-gray-600">(17k)</span>
            </div>
            <h6 className="title text-lg fw-semibold mt-12 mb-8">
              <a href="product-details.html" className="link text-line-2">
                Taylor Farms Broccoli Florets Vegetables
              </a>
            </h6>
            <div className="flex-align gap-4">
              <span className="text-main-600 text-md d-flex">
                <i className="ph-fill ph-storefront" />
              </span>
              <span className="text-gray-500 text-xs">
                By Lucky Supermarket
              </span>
            </div>
            <div className="mt-12">
              <div
                className="progress w-100  bg-color-three rounded-pill h-4"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={35}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="progress-bar bg-main-600 rounded-pill"
                  style={{ width: "35%" }}
                />
              </div>
              <span className="text-gray-900 text-xs fw-medium mt-8">
                Sold: 18/35
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section className="offer pt-80 pb-80">
  <div className="container container-lg">
    <div className="row gy-4">
      <div className="col-sm-6" data-aos="zoom-in" data-aos-duration={600}>
        <div className="offer-card position-relative rounded-16 bg-main-600 overflow-hidden p-16 flex-align gap-16 flex-wrap z-1">
          <img
            src="assets/images/shape/offer-shape.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100 opacity-6"
          />
          <div className="offer-card__thumb d-lg-block d-none">
            <img src="assets/images/thumbs/offer-img1.png" alt="" />
          </div>
          <div className="py-xl-4">
            <div className="offer-card__logo mb-16 w-80 h-80 flex-center bg-white rounded-circle">
              <img src="assets/images/thumbs/offer-logo.png" alt="" />
            </div>
            <h4 className="text-white mb-8">$5 off your first order</h4>
            <div className="flex-align gap-8">
              <span className="text-sm text-white">Delivery by 6:15am</span>
              <span className="text-sm text-main-two-600">expired Aug 5</span>
            </div>
            <a
              href="shop.html"
              className="mt-16 btn bg-white hover-text-white hover-bg-main-800 text-heading fw-medium d-inline-flex align-items-center rounded-pill gap-8"
              tabIndex={0}
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="col-sm-6" data-aos="zoom-in" data-aos-duration={800}>
        <div className="offer-card position-relative rounded-16 bg-main-600 overflow-hidden p-16 flex-align gap-16 flex-wrap z-1">
          <img
            src="assets/images/shape/offer-shape.png"
            alt=""
            className="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100 opacity-6"
          />
          <div className="offer-card__thumb d-lg-block d-none">
            <img src="assets/images/thumbs/offer-img2.png" alt="" />
          </div>
          <div className="py-xl-4">
            <div className="offer-card__logo mb-16 w-80 h-80 flex-center bg-white rounded-circle">
              <img src="assets/images/thumbs/offer-logo.png" alt="" />
            </div>
            <h4 className="text-white mb-8">$5 off your first order</h4>
            <div className="flex-align gap-8">
              <span className="text-sm text-white">Delivery by 6:15am</span>
              <span className="text-sm text-main-two-600">expired Aug 5</span>
            </div>
            <a
              href="shop.html"
              className="mt-16 btn bg-white hover-text-white hover-bg-main-800 text-heading fw-medium d-inline-flex align-items-center rounded-pill gap-8"
              tabIndex={0}
            >
              Shop Now
              <span className="icon text-xl d-flex">
                <i className="ph ph-arrow-right" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="recommended overflow-hidden">
  <div className="container container-lg">
    <div className="section-heading flex-between flex-wrap gap-16">
      <h5 className="mb-0 wow bounceInLeft">Recommended for you</h5>
      <ul
        className="nav common-tab nav-pills wow bounceInRight"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="pills-all-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-all"
            type="button"
            role="tab"
            aria-controls="pills-all"
            aria-selected="true"
          >
            All
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-grocery-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-grocery"
            type="button"
            role="tab"
            aria-controls="pills-grocery"
            aria-selected="false"
          >
            Grocery
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-fruits-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-fruits"
            type="button"
            role="tab"
            aria-controls="pills-fruits"
            aria-selected="false"
          >
            Fruits
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-juices-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-juices"
            type="button"
            role="tab"
            aria-controls="pills-juices"
            aria-selected="false"
          >
            Juices
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-vegetables-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-vegetables"
            type="button"
            role="tab"
            aria-controls="pills-vegetables"
            aria-selected="false"
          >
            Vegetables
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-snacks-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-snacks"
            type="button"
            role="tab"
            aria-controls="pills-snacks"
            aria-selected="false"
          >
            Snacks
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="pills-organic-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-organic"
            type="button"
            role="tab"
            aria-controls="pills-organic"
            aria-selected="false"
          >
            Organic Foods
          </button>
        </li>
      </ul>
    </div>
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="pills-all"
        role="tabpanel"
        aria-labelledby="pills-all-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-grocery"
        role="tabpanel"
        aria-labelledby="pills-grocery-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-fruits"
        role="tabpanel"
        aria-labelledby="pills-fruits-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-juices"
        role="tabpanel"
        aria-labelledby="pills-juices-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-vegetables"
        role="tabpanel"
        aria-labelledby="pills-vegetables-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-snacks"
        role="tabpanel"
        aria-labelledby="pills-snacks-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-organic"
        role="tabpanel"
        aria-labelledby="pills-organic-tab"
        tabIndex={0}
      >
        <div className="row g-12">
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img7.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img8.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Marcel's Modern Pantry Almond Unsweetened
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img9.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    O Organics Milk, Whole, Vitamin D
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                Best Sale{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img10.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Whole Grains and Seeds Organic Bread
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img11.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Lucerne Yogurt, Lowfat, Strawberry
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img12.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Nature Valley Whole Grain Oats and Honey Protein
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img13.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={400}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img14.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={600}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img15.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    C-500 Antioxidant Protect Dietary Supplement
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={800}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img16.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Good &amp; Gather Farmed Atlantic Salmon
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                Sale 50%{" "}
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img17.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Market Pantry 41/50 Raw Tail-Off Large Raw Shrimp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-3 col-sm-4 col-6"
            data-aos="fade-up"
            data-aos-duration={1200}
          >
            <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
              <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                New
              </span>
              <a
                href="product-details.html"
                className="product-card__thumb flex-center"
              >
                <img src="assets/images/thumbs/product-img18.png" alt="" />
              </a>
              <div className="product-card__content p-sm-2">
                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                  <a href="product-details.html" className="link text-line-2">
                    Tropicana 100% Juice, Orange, No Pulp
                  </a>
                </h6>
                <div className="flex-align gap-4">
                  <span className="text-main-600 text-md d-flex">
                    <i className="ph-fill ph-storefront" />
                  </span>
                  <span className="text-gray-500 text-xs">
                    By Lucky Supermarket
                  </span>
                </div>
                <div className="product-card__content mt-12">
                  <div className="product-card__price mb-8">
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<>
  {/* ========================= hot-deals Start ================================ */}
  <section className="hot-deals pt-80 overflow-hidden">
    <div className="container container-lg">
      <div className="section-heading">
        <div className="flex-between flex-wrap gap-8">
          <h5 className="mb-0 wow bounceInLeft">Hot Deals Todays</h5>
          <div className="flex-align gap-16 wow bounceInRight">
            <a
              href="shop.html"
              className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
            >
              View All Deals
            </a>
            <div className="flex-align gap-8">
              <button
                type="button"
                id="deals-prev"
                className="slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
              >
                <i className="ph ph-caret-left" />
              </button>
              <button
                type="button"
                id="deals-next"
                className="slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
              >
                <i className="ph ph-caret-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-12">
        <div className="col-md-4" data-aos="zoom-in">
          <div className="hot-deals position-relative rounded-16 bg-main-600 overflow-hidden p-28 z-1 text-center">
            <img
              src="assets/images/shape/offer-shape.png"
              alt=""
              className="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100 opacity-6"
            />
            <div className="hot-deals__thumb">
              <img src="assets/images/thumbs/hot-deals-img.png" alt="" />
            </div>
            <div className="py-xl-4">
              <h4 className="text-white mb-8">Fresh Vegetables</h4>
              <div className="countdown my-32" id="countdown4">
                <ul className="countdown-list flex-center flex-wrap">
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="days" />
                    Days
                  </li>
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="hours" />
                    Hours
                  </li>
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="minutes" />
                    Min
                  </li>
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="seconds" />
                    Sec
                  </li>
                </ul>
              </div>
              <a
                href="shop.html"
                className="mt-16 btn btn-main-two fw-medium d-inline-flex align-items-center rounded-pill gap-8"
                tabIndex={0}
              >
                Shop Now
                <span className="icon text-xl d-flex">
                  <i className="ph ph-arrow-right" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="hot-deals-slider arrow-style-two">
            <div data-aos="fade-up" data-aos-duration={200}>
              <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                  Sale 50%{" "}
                </span>
                <a
                  href="product-details.html"
                  className="product-card__thumb flex-center"
                >
                  <img src="assets/images/thumbs/product-img8.png" alt="" />
                </a>
                <div className="product-card__content p-sm-2">
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Marcel's Modern Pantry Almond Unsweetened
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="product-card__price mb-8">
                      <span className="text-heading text-md fw-semibold ">
                        $14.99{" "}
                        <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                      </span>
                      <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                        {" "}
                        $28.99
                      </span>
                    </div>
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-600">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-600">
                        (17k)
                      </span>
                    </div>
                    <a
                      href="cart.html"
                      className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                    >
                      Add To Cart <i className="ph ph-shopping-cart" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration={400}>
              <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                  Sale 50%{" "}
                </span>
                <a
                  href="product-details.html"
                  className="product-card__thumb flex-center"
                >
                  <img src="assets/images/thumbs/product-img9.png" alt="" />
                </a>
                <div className="product-card__content p-sm-2">
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      O Organics Milk, Whole, Vitamin D
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="product-card__price mb-8">
                      <span className="text-heading text-md fw-semibold ">
                        $14.99{" "}
                        <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                      </span>
                      <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                        {" "}
                        $28.99
                      </span>
                    </div>
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-600">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-600">
                        (17k)
                      </span>
                    </div>
                    <a
                      href="cart.html"
                      className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                    >
                      Add To Cart <i className="ph ph-shopping-cart" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration={600}>
              <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                <span className="product-card__badge bg-info-600 px-8 py-4 text-sm text-white">
                  Best Sale{" "}
                </span>
                <a
                  href="product-details.html"
                  className="product-card__thumb flex-center"
                >
                  <img src="assets/images/thumbs/product-img10.png" alt="" />
                </a>
                <div className="product-card__content p-sm-2">
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Whole Grains and Seeds Organic Bread
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="product-card__price mb-8">
                      <span className="text-heading text-md fw-semibold ">
                        $14.99{" "}
                        <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                      </span>
                      <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                        {" "}
                        $28.99
                      </span>
                    </div>
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-600">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-600">
                        (17k)
                      </span>
                    </div>
                    <a
                      href="cart.html"
                      className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                    >
                      Add To Cart <i className="ph ph-shopping-cart" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration={800}>
              <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white">
                  New
                </span>
                <a
                  href="product-details.html"
                  className="product-card__thumb flex-center"
                >
                  <img src="assets/images/thumbs/product-img18.png" alt="" />
                </a>
                <div className="product-card__content p-sm-2">
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Tropicana 100% Juice, Orange, No Pulp
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="product-card__price mb-8">
                      <span className="text-heading text-md fw-semibold ">
                        $14.99{" "}
                        <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                      </span>
                      <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                        {" "}
                        $28.99
                      </span>
                    </div>
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-600">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-600">
                        (17k)
                      </span>
                    </div>
                    <a
                      href="cart.html"
                      className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                    >
                      Add To Cart <i className="ph ph-shopping-cart" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration={1000}>
              <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                  Sale 50%{" "}
                </span>
                <a
                  href="product-details.html"
                  className="product-card__thumb flex-center"
                >
                  <img src="assets/images/thumbs/product-img9.png" alt="" />
                </a>
                <div className="product-card__content p-sm-2">
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      O Organics Milk, Whole, Vitamin D
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="product-card__price mb-8">
                      <span className="text-heading text-md fw-semibold ">
                        $14.99{" "}
                        <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                      </span>
                      <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                        {" "}
                        $28.99
                      </span>
                    </div>
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-600">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-600">
                        (17k)
                      </span>
                    </div>
                    <a
                      href="cart.html"
                      className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                    >
                      Add To Cart <i className="ph ph-shopping-cart" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================= hot-deals End ================================ */}
  {/* ============================== Top Vendors Section Start ================================= */}
  <section className="top-vendors py-80">
    <div className="container container-lg">
      <div className="section-heading">
        <div className="flex-between flex-wrap gap-8">
          <h5 className="mb-0">Weekly Top Vendors</h5>
          <a
            href="shop.html"
            className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
          >
            All Vendors
          </a>
        </div>
      </div>
      <div className="row gy-4 vendor-card-wrapper">
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={200}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo1.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Organic Market</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={400}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo2.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Safeway</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={600}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo3.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Food Max</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={800}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo4.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">HRmart</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={200}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo5.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Lucky Supermarket</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={400}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo6.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Arico Farmer</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={600}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo7.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Farmer Market</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={800}
        >
          <div className="vendor-card text-center px-16 pb-24">
            <div className="">
              <img
                src="assets/images/thumbs/vendor-logo8.png"
                alt=""
                className="vendor-card__logo m-12"
              />
              <h6 className="title mt-32">Foodsco</h6>
              <span className="text-heading text-sm d-block">
                Delivery by 6:15am
              </span>
              <a
                href="shop.html"
                className="btn btn-main-two rounded-pill py-6 px-16 text-12 mt-8"
              >
                $5 off Snack &amp; Candy
              </a>
            </div>
            <div className="vendor-card__list mt-22 flex-center flex-wrap gap-8">
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img1.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img2.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img3.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img4.png" alt="" />
              </div>
              <div className="vendor-card__item bg-white rounded-circle flex-center">
                <img src="assets/images/thumbs/vendor-img5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ============================== Top Vendors Section End ================================= */}
  {/* ========================= best sells Start ================================ */}
  <section className="best sells pb-80">
    <div className="container container-lg">
      <div className="section-heading">
        <div className="flex-between flex-wrap gap-8">
          <h5 className="mb-0 wow bounceInLeft">Daily Best Sells</h5>
        </div>
      </div>
      <div className="row g-12">
        <div className="col-xxl-8">
          <div className="row gy-4">
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <div className="product-card style-two h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 flex-align gap-16">
                <div className="">
                  <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                    Sale 50%{" "}
                  </span>
                  <a
                    href="product-details.html"
                    className="product-card__thumb flex-center"
                  >
                    <img src="assets/images/thumbs/best-sell1.png" alt="" />
                  </a>
                  <div className="countdown" id="countdown6">
                    <ul className="countdown-list style-three flex-align flex-wrap">
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="days" />
                        Days
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="hours" />
                        Hours
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="minutes" />
                        Min
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="seconds" />
                        Sec
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-card__content">
                  <div className="product-card__price mb-16">
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Taylor Farms Broccoli Florets Vegetables
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="mt-12">
                    <div
                      className="progress w-100  bg-color-three rounded-pill h-4"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={35}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-main-600 rounded-pill"
                        style={{ width: "35%" }}
                      />
                    </div>
                    <span className="text-gray-900 text-xs fw-medium mt-8">
                      Sold: 18/35
                    </span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="product-card style-two h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 flex-align gap-16">
                <div className="">
                  <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                    Sale 50%{" "}
                  </span>
                  <a
                    href="product-details.html"
                    className="product-card__thumb flex-center"
                  >
                    <img src="assets/images/thumbs/best-sell2.png" alt="" />
                  </a>
                  <div className="countdown" id="countdown7">
                    <ul className="countdown-list style-three flex-align flex-wrap">
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="days" />
                        Days
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="hours" />
                        Hours
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="minutes" />
                        Min
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="seconds" />
                        Sec
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-card__content">
                  <div className="product-card__price mb-16">
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Taylor Farms Broccoli Florets Vegetables
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="mt-12">
                    <div
                      className="progress w-100  bg-color-three rounded-pill h-4"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={35}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-main-600 rounded-pill"
                        style={{ width: "35%" }}
                      />
                    </div>
                    <span className="text-gray-900 text-xs fw-medium mt-8">
                      Sold: 18/35
                    </span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <div className="product-card style-two h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 flex-align gap-16">
                <div className="">
                  <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                    Sale 50%{" "}
                  </span>
                  <a
                    href="product-details.html"
                    className="product-card__thumb flex-center"
                  >
                    <img src="assets/images/thumbs/best-sell3.png" alt="" />
                  </a>
                  <div className="countdown" id="countdown8">
                    <ul className="countdown-list style-three flex-align flex-wrap">
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="days" />
                        Days
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="hours" />
                        Hours
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="minutes" />
                        Min
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="seconds" />
                        Sec
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-card__content">
                  <div className="product-card__price mb-16">
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Taylor Farms Broccoli Florets Vegetables
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="mt-12">
                    <div
                      className="progress w-100  bg-color-three rounded-pill h-4"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={35}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-main-600 rounded-pill"
                        style={{ width: "35%" }}
                      />
                    </div>
                    <span className="text-gray-900 text-xs fw-medium mt-8">
                      Sold: 18/35
                    </span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="product-card style-two h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 flex-align gap-16">
                <div className="">
                  <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white">
                    Sale 50%{" "}
                  </span>
                  <a
                    href="product-details.html"
                    className="product-card__thumb flex-center"
                  >
                    <img src="assets/images/thumbs/best-sell4.png" alt="" />
                  </a>
                  <div className="countdown" id="countdown9">
                    <ul className="countdown-list style-three flex-align flex-wrap">
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="days" />
                        Days
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="hours" />
                        Hours
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="minutes" />
                        Min
                      </li>
                      <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium">
                        <span className="seconds" />
                        Sec
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-card__content">
                  <div className="product-card__price mb-16">
                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                      {" "}
                      $28.99
                    </span>
                    <span className="text-heading text-md fw-semibold ">
                      $14.99{" "}
                      <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                    </span>
                  </div>
                  <div className="flex-align gap-6">
                    <span className="text-xs fw-bold text-gray-600">4.8</span>
                    <span className="text-15 fw-bold text-warning-600 d-flex">
                      <i className="ph-fill ph-star" />
                    </span>
                    <span className="text-xs fw-bold text-gray-600">(17k)</span>
                  </div>
                  <h6 className="title text-lg fw-semibold mt-12 mb-8">
                    <a href="product-details.html" className="link text-line-2">
                      Taylor Farms Broccoli Florets Vegetables
                    </a>
                  </h6>
                  <div className="flex-align gap-4">
                    <span className="text-main-600 text-md d-flex">
                      <i className="ph-fill ph-storefront" />
                    </span>
                    <span className="text-gray-500 text-xs">
                      By Lucky Supermarket
                    </span>
                  </div>
                  <div className="mt-12">
                    <div
                      className="progress w-100  bg-color-three rounded-pill h-4"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={35}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-main-600 rounded-pill"
                        style={{ width: "35%" }}
                      />
                    </div>
                    <span className="text-gray-900 text-xs fw-medium mt-8">
                      Sold: 18/35
                    </span>
                  </div>
                  <a
                    href="cart.html"
                    className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                  >
                    Add To Cart <i className="ph ph-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-4" data-aos="zoom-in" data-aos-duration={600}>
          <div className="position-relative rounded-16 bg-light-purple overflow-hidden p-28 z-1 text-center">
            <div className="">
              <img
                src="assets/images/bg/special-snacks.png"
                alt=""
                className="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100 cover-img"
              />
              <div className="d-xxl-block d-none">
                <img src="assets/images/thumbs/special-snacks-img.png" alt="" />
              </div>
            </div>
            <div className="py-xl-4">
              <h4 className="mb-8">Special Snacks</h4>
              <div className="countdown my-32" id="countdown5">
                <ul className="countdown-list style-two flex-center flex-wrap">
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="days" />
                    Days
                  </li>
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="hours" />
                    Hours
                  </li>
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="minutes" />
                    Min
                  </li>
                  <li className="countdown-list__item text-heading flex-align gap-4 text-sm fw-medium colon-white">
                    <span className="seconds" />
                    Sec
                  </li>
                </ul>
              </div>
              <a
                href="shop.html"
                className="mt-16 btn btn-main-two fw-medium d-inline-flex align-items-center rounded-pill gap-8"
                tabIndex={0}
              >
                Shop Now
                <span className="icon text-xl d-flex">
                  <i className="ph ph-arrow-right" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================= best sells End ================================ */}
  {/* ========================= Delivery Section Start ================================ */}
  <div className="delivery-section">
    <div className="container container-lg">
      <div className="delivery position-relative rounded-16 bg-main-600 p-16 flex-align gap-16 flex-wrap z-1">
        <img
          src="assets/images/bg/delivery-bg.png"
          alt=""
          className="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100"
        />
        <div className="row align-items-center">
          <div className="col-md-3 d-md-block d-none">
            <div
              className="delivery__man text-center"
              data-aos="fade-down-right"
            >
              <img src="assets/images/thumbs/delivery-man.png" alt="" />
            </div>
          </div>
          <div className="col-md-5 col-sm-7">
            <div className="text-center">
              <h4 className="text-white mb-8" data-aos="fade-down">
                We Delivery on Next Day from 10:00 AM to 08:00 PM
              </h4>
              <p className="text-white" data-aos="zoom-in">
                For Orders starts from $100
              </p>
              <a
                href="shop.html"
                className="mt-16 btn btn-main-two fw-medium d-inline-flex align-items-center rounded-pill gap-8"
                data-aos="fade-up"
                tabIndex={0}
              >
                Shop Now
                <span className="icon text-xl d-flex">
                  <i className="ph ph-arrow-right" />
                </span>
              </a>
            </div>
          </div>
          <div
            className="col-md-4 col-sm-5 d-sm-block d-none"
            data-aos="zoom-out"
            data-aos-duration={800}
          >
            <img src="assets/images/thumbs/special-snacks-img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* ========================= Delivery Section End ================================ */}
  {/* ========================= organic food Start ================================ */}
  <section className="organic-food py-80 overflow-hidden">
    <div className="container container-lg">
      <div className="section-heading">
        <div className="flex-between flex-wrap gap-8">
          <h5 className="mb-0 wow bounceInLeft">Organic Food</h5>
          <div className="flex-align gap-16 wow bounceInRight">
            <a
              href="shop.html"
              className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
            >
              {" "}
              All Categories
            </a>
            <div className="flex-align gap-8">
              <button
                type="button"
                id="organic-prev"
                className="slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
              >
                <i className="ph ph-caret-left" />
              </button>
              <button
                type="button"
                id="organic-next"
                className="slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
              >
                <i className="ph ph-caret-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="organic-food__slider arrow-style-two">
        <div data-aos="fade-up" data-aos-duration={200}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img20.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={400}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img21.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={600}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img22.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={800}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img23.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1000}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img24.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1200}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img25.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1400}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img21.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  Taylor Farms Broccoli Florets Vegetables
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================= organic food End ================================ */}
  {/* ========================== Short Product Section Start ============================== */}
  <div className="short-product">
    <div className="container container-lg">
      <div className="row gy-4">
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration={600}
        >
          <div className="p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 ">
            <div className="p-16 bg-main-50 rounded-16 mb-32">
              <h6 className="underlined-line position-relative mb-0 pb-16 d-inline-block">
                Featured Products
              </h6>
            </div>
            <div className="short-product-list arrow-style-two">
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img1.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img2.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img3.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img4.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img1.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img2.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img3.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img4.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration={800}
        >
          <div className="p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 ">
            <div className="p-16 bg-main-50 rounded-16 mb-32">
              <h6 className="underlined-line position-relative mb-0 pb-16 d-inline-block">
                Top Selling Products
              </h6>
            </div>
            <div className="short-product-list arrow-style-two">
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img5.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img6.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img7.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img8.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img5.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img6.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img7.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img8.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          <div className="p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 ">
            <div className="p-16 bg-main-50 rounded-16 mb-32">
              <h6 className="underlined-line position-relative mb-0 pb-16 d-inline-block">
                On-sale Products
              </h6>
            </div>
            <div className="short-product-list arrow-style-two">
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img9.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img4.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img7.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img4.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img9.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img4.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img7.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img4.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration={1200}
        >
          <div className="p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 ">
            <div className="p-16 bg-main-50 rounded-16 mb-32">
              <h6 className="underlined-line position-relative mb-0 pb-16 d-inline-block">
                Top Rated Products
              </h6>
            </div>
            <div className="short-product-list arrow-style-two">
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img3.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img7.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img1.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img8.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img1.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img7.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16 mb-40">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img3.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-align gap-16">
                  <div className="w-90 h-90 rounded-12 border border-gray-100 flex-shrink-0">
                    <a href="product-details.html" className="link">
                      <img
                        src="assets/images/thumbs/short-product-img8.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="product-card__content mt-12">
                    <div className="flex-align gap-6">
                      <span className="text-xs fw-bold text-gray-500">4.8</span>
                      <span className="text-15 fw-bold text-warning-600 d-flex">
                        <i className="ph-fill ph-star" />
                      </span>
                      <span className="text-xs fw-bold text-gray-500">
                        (17k)
                      </span>
                    </div>
                    <h6 className="title text-lg fw-semibold mt-8 mb-8">
                      <a
                        href="product-details.html"
                        className="link text-line-1"
                      >
                        Taylor Farms Broccoli Florets Vegetables
                      </a>
                    </h6>
                    <div className="product-card__price flex-align gap-8">
                      <span className="text-heading text-md fw-semibold d-block">
                        $1500.00
                      </span>
                      <span className="text-gray-400 text-md fw-semibold d-block">
                        $1500.00
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* ========================== Short Product Section End ============================== */}
  {/* ============================== Brand Section Start =============================== */}
  <div className="brand py-80 overflow-hidden">
    <div className="container container-lg">
      <div className="brand-inner bg-color-one p-24 rounded-16">
        <div className="section-heading">
          <div className="flex-between flex-wrap gap-8">
            <h5 className="mb-0 wow bounceInLeft">Shop by Brands</h5>
            <div className="flex-align gap-16 wow bounceInRight">
              <a
                href="shop.html"
                className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
              >
                View All Deals
              </a>
              <div className="flex-align gap-8">
                <button
                  type="button"
                  id="brand-prev"
                  className="slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
                >
                  <i className="ph ph-caret-left" />
                </button>
                <button
                  type="button"
                  id="brand-next"
                  className="slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
                >
                  <i className="ph ph-caret-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="brand-slider arrow-style-two">
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={200}
          >
            <img src="assets/images/thumbs/brand-img1.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={400}
          >
            <img src="assets/images/thumbs/brand-img2.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={600}
          >
            <img src="assets/images/thumbs/brand-img3.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={800}
          >
            <img src="assets/images/thumbs/brand-img4.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={1000}
          >
            <img src="assets/images/thumbs/brand-img5.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={1200}
          >
            <img src="assets/images/thumbs/brand-img6.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={1400}
          >
            <img src="assets/images/thumbs/brand-img7.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={1600}
          >
            <img src="assets/images/thumbs/brand-img8.png" alt="" />
          </div>
          <div
            className="brand-item"
            data-aos="zoom-in"
            data-aos-duration={1800}
          >
            <img src="assets/images/thumbs/brand-img3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* ============================== Brand Section End =============================== */}
  {/* ========================== New Arrival Section Start ============================= */}
  <section className="new-arrival pb-80">
    <div className="container container-lg">
      <div className="section-heading">
        <div className="flex-between flex-wrap gap-8">
          <h5 className="mb-0">New Arrivals</h5>
          <div className="flex-align gap-16">
            <a
              href="shop.html"
              className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
            >
              View All Deals
            </a>
            <div className="flex-align gap-8">
              <button
                type="button"
                id="new-arrival-prev"
                className="slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
              >
                <i className="ph ph-caret-left" />
              </button>
              <button
                type="button"
                id="new-arrival-next"
                className="slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-main-600 text-xl hover-bg-main-600 hover-text-white transition-1"
              >
                <i className="ph ph-caret-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="new-arrival__slider arrow-style-two">
        <div data-aos="fade-up" data-aos-duration={200}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img20.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={400}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img21.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={600}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img22.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={800}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img23.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1000}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img24.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1200}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img25.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1400}>
          <div className="product-card px-8 py-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
            <a
              href="product-details.html"
              className="product-card__thumb flex-center"
            >
              <img src="assets/images/thumbs/product-img21.png" alt="" />
            </a>
            <div className="product-card__content mt-12">
              <div className="flex-align gap-6">
                <span className="text-xs fw-bold text-gray-500">4.8</span>
                <span className="text-15 fw-bold text-warning-600 d-flex">
                  <i className="ph-fill ph-star" />
                </span>
                <span className="text-xs fw-bold text-gray-500">(17k)</span>
              </div>
              <h6 className="title text-lg fw-semibold mt-12 mb-8">
                <a href="product-details.html" className="link text-line-2">
                  C-500 Antioxidant Protect Dietary Supplement
                </a>
              </h6>
              <div className="flex-align gap-4">
                <span className="text-main-600 text-md d-flex">
                  <i className="ph-fill ph-storefront" />
                </span>
                <span className="text-gray-500 text-xs">
                  By Lucky Supermarket
                </span>
              </div>
              <div className="flex-between gap-8 mt-24 flex-wrap">
                <div className="product-card__price">
                  <span className="text-gray-400 text-md fw-semibold text-decoration-line-through d-block">
                    {" "}
                    $28.99
                  </span>
                  <span className="text-heading text-md fw-semibold ">
                    $14.99 <span className="text-gray-500 fw-normal">/Qty</span>{" "}
                  </span>
                </div>
                <a
                  href="cart.html"
                  className="product-card__cart btn btn-main py-11 px-24 rounded-pill flex-align gap-8"
                >
                  Add <i className="ph ph-shopping-cart" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================== New Arrival Section End ============================= */}
  {/* ========================== Shipping Section Start ============================ */}
  <section className="shipping mb-24" id="shipping">
    <div className="container container-lg">
      <div className="row gy-4">
        <div
          className="col-xxl-3 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={400}
        >
          <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-32 flex-shrink-0">
              <i className="ph-fill ph-car-profile" />
            </span>
            <div className="">
              <h6 className="mb-0">Free Shipping</h6>
              <span className="text-sm text-heading">
                Free shipping all over the US
              </span>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={600}
        >
          <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-32 flex-shrink-0">
              <i className="ph-fill ph-hand-heart" />
            </span>
            <div className="">
              <h6 className="mb-0"> 100% Satisfaction</h6>
              <span className="text-sm text-heading">
                Free shipping all over the US
              </span>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={800}
        >
          <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-32 flex-shrink-0">
              <i className="ph-fill ph-credit-card" />
            </span>
            <div className="">
              <h6 className="mb-0"> Secure Payments</h6>
              <span className="text-sm text-heading">
                Free shipping all over the US
              </span>
            </div>
          </div>
        </div>
        <div
          className="col-xxl-3 col-sm-6"
          data-aos="zoom-in"
          data-aos-duration={1000}
        >
          <div className="shipping-item flex-align gap-16 rounded-16 bg-main-50 hover-bg-main-100 transition-2">
            <span className="w-56 h-56 flex-center rounded-circle bg-main-600 text-white text-32 flex-shrink-0">
              <i className="ph-fill ph-chats" />
            </span>
            <div className="">
              <h6 className="mb-0"> 24/7 Support</h6>
              <span className="text-sm text-heading">
                Free shipping all over the US
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ========================== Shipping Section End ============================ */}
  {/* =============================== Newsletter Section Start ============================ */}
  <div className="newsletter">
    <div className="container container-lg">
      <div className="newsletter-box position-relative rounded-16 flex-align gap-16 flex-wrap z-1">
        <img
          src="assets/images/bg/newsletter-bg.png"
          alt=""
          className="position-absolute inset-block-start-0 inset-inline-start-0 z-n1 w-100 h-100 opacity-6"
        />
        <div className="row align-items-center">
          <div className="col-xl-6">
            <div className="">
              <h1
                className="text-white mb-12"
                data-aos="fade-down"
                data-aos-duration={800}
              >
                Don't Miss Out on Grocery Deals
              </h1>
              <p
                className="text-white h5 mb-0"
                data-aos="fade-up"
                data-aos-duration={800}
              >
                SING UP FOR THE UPDATE NEWSLETTER
              </p>
              <form
                action="#"
                className="position-relative mt-40"
                data-aos="zoom-in"
                data-aos-duration={800}
              >
                <input
                  type="text"
                  className="form-control common-input rounded-pill text-white py-22 px-16 pe-144"
                  placeholder="Your email address..."
                />
                <button
                  type="submit"
                  className="btn btn-main-two rounded-pill position-absolute top-50 translate-middle-y inset-inline-end-0 me-10"
                >
                  Subscribe{" "}
                </button>
              </form>
            </div>
          </div>
          <div className="col-xl-6 text-center d-xl-block d-none">
            <img
              src="assets/images/thumbs/newsletter-img.png"
              alt=""
              data-aos="zoom-in"
              data-aos-duration={800}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</>



</>


  );
}

export default Home;
