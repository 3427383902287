import Header from "./Sections/Header";
import Footer from "./Sections/Footer";
import Home from "./Pages/Home";

function App() {
  return (
  <>
     <Header />
     <Home />
     <Footer />
  </>
  );
}

export default App;
