
function Footer() {
  return (
  <>
<footer className="footer py-80 overflow-hidden">
  <div className="container container-lg">
    <div className="footer-item-two-wrapper d-flex align-items-start flex-wrap">
      <div
        className="footer-item max-w-275"
        data-aos="fade-up"
        data-aos-duration={200}
      >
        <div className="footer-item__logo">
          <a href="index.html">
            {" "}
            <img src="assets/images/logo/logo.png" alt="" />
          </a>
        </div>
        <p className="mb-24">
        Connect2Buyers aims to become the leading provider of computer parts, gaming PC components, and other IT-related products.

        </p>
        <div className="flex-align gap-16 mb-16">
          <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
            <i className="ph-fill ph-phone-call" />
          </span>
          <a
            href="tel:+00123456789"
            className="text-md text-gray-900 hover-text-main-600"
          >
            +00 123 456 789
          </a>
        </div>
        <div className="flex-align gap-16 mb-16">
          <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
            <i className="ph-fill ph-envelope" />
          </span>
          <a
            href="mailto:support24@marketpro.com"
            className="text-md text-gray-900 hover-text-main-600"
          >
            support24@Connect2Buyers.com
          </a>
        </div>
        <div className="flex-align gap-16 mb-16">
          <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
            <i className="ph-fill ph-map-pin" />
          </span>
          <span className="text-md text-gray-900 ">
            789 Inner Lane, California, USA
          </span>
        </div>
      </div>
      <div className="footer-item" data-aos="fade-up" data-aos-duration={400}>
        <h6 className="footer-item__title">About us</h6>
        <ul className="footer-menu">
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Company Profile
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              All Retail Store
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Merchant Center
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Affiliate
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Contact Us
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Feedback
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Huawei Group
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Rules &amp; Policy
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-item" data-aos="fade-up" data-aos-duration={600}>
        <h6 className="footer-item__title">Customer Support</h6>
        <ul className="footer-menu">
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Help Center
            </a>
          </li>
          <li className="mb-16">
            <a
              href="contact.html"
              className="text-gray-600 hover-text-main-600"
            >
              Contact Us
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Gift Card
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Report Abuse
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Submit and Dispute
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Policies &amp; Rules
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Online Shopping
            </a>
          </li>
          <li className="">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Redeem Voucher
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-item" data-aos="fade-up" data-aos-duration={800}>
        <h6 className="footer-item__title">My Account</h6>
        <ul className="footer-menu">
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              My Account
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Order History
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Shoping Cart
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Compare
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Help Ticket
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Wishlist
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Order History
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Product Support
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-item" data-aos="fade-up" data-aos-duration={1000}>
        <h6 className="footer-item__title">Information</h6>
        <ul className="footer-menu">
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Become a Vendor
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Affiliate Program
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Privacy Policy
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Our Suppliers
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Extended Plan
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Extended Plan
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Community
            </a>
          </li>
          <li className="mb-16">
            <a href="shop.html" className="text-gray-600 hover-text-main-600">
              Community
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-item" data-aos="fade-up" data-aos-duration={1200}>
        <div className="flex-align gap-8 my-32">
        </div>
        <ul className="flex-align gap-16">
          <li>
            <a
              href="https://www.facebook.com/"
              className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
            >
              <i className="ph-fill ph-facebook-logo" />
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/"
              className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
            >
              <i className="ph-fill ph-twitter-logo" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/"
              className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
            >
              <i className="ph-fill ph-instagram-logo" />
            </a>
          </li>
          <li>
            <a
              href="https://www.pinterest.com/"
              className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
            >
              <i className="ph-fill ph-linkedin-logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

  </>
  );
}

export default Footer;
